import React from 'react'

const Portfoliobuild = () => {
  return (
    <section id='portfoliouild'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>
      <div className="container portfolio__container"><h1>Section will be updated soon! Stay tuned!</h1></div>
    </section>
  )
}

export default Portfoliobuild